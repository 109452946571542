<template>
  <div>
    <div class="row" v-if="jobData.length != 0">
      <div
          class="col-xl-12 m-auto"
          v-for="(job, index) in jobData"
          :key="index"
      >
        <div class="row">
          <div class="col-xl-9">
            <div class="card px-2">
              <div
                  class="card-header mt-2 d-flex justify-content-between w-100"
              >
                <div class="header text-left">
                  <h4 style="color: #2b882f">{{ job.title }}</h4>
                  <p
                      style="
                      margin-left: 0 !important;
                      margin-bottom: 5px;
                      font-size: 18px;
                    "
                  >
                    <b>{{ job.company_name }}</b>
                  </p>
                </div>
                <div class="div" v-if="job.company_logo != 'Not Available'">
                  <img :src="job.company_logo" alt="image"/>
                </div>
              </div>
              <div class="card-body">
                <h5 style="margin-top: 15px">Job Length</h5>
                <p class="mb-1">{{ job.length }}</p>
                <div v-html="job.summary"></div>
                <h5>Skills Requirements</h5>
                <ul>
                  <li v-for="(skill, num) in job.skills" :key="num">
                    {{ skill.name }} ({{ skill.type }})
                  </li>
                </ul>
                <h5 v-if="job.address && job.upazilla && job.district && job.division">Job Location</h5>
                <p v-if="job.address && job.upazilla && job.district && job.division">
                  {{ job.address }},{{ job.upazilla }},{{ job.district }},{{
                    job.division
                  }}
                </p>

                <h5 v-if="job.estimated_start_date != null">
                  Estimated starting date
                </h5>
                <p v-if="job.estimated_start_date != null">
                  {{ job.estimated_start_date }}
                </p>
                <div class="div text-right">
                  <h5>Created By</h5>
                  <p>{{ job.created_by }}</p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-xl-3">
            <div class="card">
              <div class="card-header bg-primary">
                <h5 class="text-white mt-0 mb-0">Job Summary</h5>
              </div>
              <div class="card-body summary border">
                <p class="mt-2"><b>Published on:</b> {{ job.created_at }}</p>
                <p>
                  <b>Vacancy: {{ job.vacancy }}</b>
                </p>
                <p><b>Seniority: </b> {{ job.seniority }}</p>
                <p><b>Employment Status: </b>{{ job.commitment }}</p>
                <p><b>Workplace: </b>{{ job.workplace }}</p>
                <p><b>Experience: </b>{{ job.required_experience }} Years</p>
                <p v-if="job.address && job.upazilla && job.district && job.division">
                  <b>Job Location: </b>
                  {{ job.address }},{{ job.upazilla }},{{ job.district }},{{ job.division }}
                </p>
                <p>
                  <b>Salary: </b>{{ job.negotiable }} ({{ job.salary_min }} -
                                 {{ job.salary_max }} BDT)
                </p>
                <p><b>Deadline:</b> {{ job.deadline }}</p>

                <div v-if="(googleAccountExists != null) && (!googleAccountExists)" class=" d-inline">
                  <div class="alert alert-warning p-1 m-0"
                       role="alert">
                    <b>Notable:</b> Please give your <b>google account</b> access for calender integration. Unless you
                                    cannot
                    <b>apply</b> this job.
                  </div>

                  <div class="float-right ml-1" style="margin-top: 8px">
                    <b-button @click="redirectToGoogleAccess"
                              class="text-right d-flex"
                              style="padding-top: 7px;padding-bottom: 7px;">
                      <img src="/google-logo.png" alt="Google Logo"
                           style="height: 20px; width: 20px; margin-right: 5px">
                      <span style="padding-top: 3px">Access</span>
                    </b-button>
                  </div>
                </div>
              </div>
              <div class="text-right mr-1 p-1">
                <button
                    @click="
                    job.questions.length ? jobApplyModal() : submitApplication()
                  "
                    class="btn btn-primary"
                    :disabled="job.is_applied || !googleAccountExists"
                >
                  {{ job.is_applied ? "Applied" : "Apply" }}
                </button>
              </div>
              <Modal
                  v-if="job.questions.length"
                  v-model="jobModal"
                  :footer-hide="true"
                  title="Job Questions"
              >
                <b-form
                    @submit.prevent="
                    questionComplete ? submitApplication() : NextStep()
                  "
                >
                  <div
                      class="form-group"
                      v-if="!questionComplete"
                      v-for="(question, index) in job.questions"
                      :key="index"
                  >
                    <label style="font-size: 15px"
                    >{{
                        question.question.charAt(0).toUpperCase() +
                        question.question.slice(1)
                     }}
                     ?</label
                    >
                    <Input
                        v-model="form.answers[index]['answer']"
                        placeholder="Enter your answer"
                        style="width: 100%"
                        :type="
                        question.answer_type == 'Integer'
                          ? 'number'
                          : 'textarea'
                      "
                        @on-change="checkAnswer"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div class="form-group" v-if="questionComplete">
                    <label style="font-size: 15px">Cover Letter</label>
                    <Input
                        v-model="form.cover_letter"
                        placeholder="Enter your cover letter (Optional)"
                        style="width: 100%"
                        type="textarea"
                    />
                    <has-error :form="form" field="name"></has-error>
                  </div>
                  <div
                      class="d-flex align-items-center mt-2 justify-content-end"
                  >
                    <div>
                      <b-button
                          variant="primary"
                          type="submit"
                          :disabled="form.busy || isAnswer != 0"
                      >
                        {{ questionComplete ? "Submit" : "Next" }}
                      </b-button>
                    </div>
                  </div>
                </b-form>
              </Modal>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {BButton, BForm, BFormInput, BFormGroup} from "bootstrap-vue";
import {HasError} from "vform/src/components/bootstrap5";

export default {
  data() {
    return {
      id: this.$route.params.id,
      jobData: {},
      jobModal: false,
      questionComplete: false,
      form: new Form({
        company_job_id: null,
        answers: [],
        cover_letter: null,
      }),
      isApplied: false,
      isAnswer: true,
      googleAccountExists: null,
    };
  },
  components: {
    HasError,
    BButton,
    BForm,
    BFormInput,
    BFormGroup,
  },
  mounted() {
    this.getJob();
    this.googleAccountCheck();
  },
  methods: {
    redirectToGoogleAccess() {
      axios
          .get("/candidate/google/access")
          .then((res) => {
            window.location.href = res.data.auth_url;
          })
          .catch((e) => {
            this.e(e.data.error);
          });
    },

    getJob() {
      axios
          .get("/candidate/company/job/" + this.$route.params.id)
          .then((res) => {
            this.jobData = res.data;
            if (this.jobData.data.questions.length > 0) {
              this.jobData.data.questions.forEach((question, index) => {
                const answerObject = {};
                answerObject.answer = null;
                answerObject.question_id = question.id;
                this.form.answers.push(answerObject);
              });
              this.checkAnswer();
            }
          })
          .catch((e) => {
            this.e(e.response.data.error);
          });
    },

    googleAccountCheck() {
      axios
          .get("/candidate/google-account-check")
          .then((res) => {
            this.googleAccountExists = res.data.account;
          })
          .catch((e) => {
            this.e(e.response.data.error);
          });
    },

    submitApplication() {
      this.form.company_job_id = this.jobData.data.id;
      this.form
          .post("/candidate/company/job/application/apply")
          .then((res) => {
            this.s(res.data.message);
            this.jobModal = false;
            this.getJob();
          })
          .catch((e) => {
            this.e(e.response.data.message);
          });
    },
    jobApplyModal() {
      this.jobModal = true;
      this.questionComplete = false;
    },
    NextStep() {
      this.questionComplete = true;
    },
    checkAnswer() {
      this.isAnswer = this.form.answers.filter(
          (obj, index) => obj["answer"] == null || obj["answer"] == ""
      ).length;
    },
  },
};
</script>
<style scoped>
h5 {
  color: #333333;
  text-transform: capitalize;
  margin-top: 25px;
}

p {
  font-size: 16px;
  margin-left: 25px;
  font-weight: 400;
  color: #5c5c5c;
}

ul li {
  margin-top: 10px;
  color: #5c5c5c;
  margin-left: 40px;
}

.summary p {
  margin-left: 0;
}
</style>
